import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'
import '../../styles/assets/js/bootstrap.min.css'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import ReactPixel from 'react-facebook-pixel'
import axios from 'axios'
import { connectNode } from '../../utils/global'
import { loadLocationWithMessage } from '../../actions/location'
import '../../styles/assets/css/compare/dauten.css'
import '../../styles/assets/css/compare/compare_new_1.css'
import { Helmet } from "react-helmet"
import ReactPlayer from 'react-player'
import NavigationIcon from '@material-ui/icons/Navigation'

class ResearchCompareNew1 extends Component {
  constructor() {
      super()
      this.state = {
        language: 'en'
      }
  }

  componentDidMount() {
    ReactPixel.init('1230911777821192');
    ReactPixel.pageView()
  }

  handleEnrollClick = async (e) => {
    window.open("https://redcap.partners.org/redcap/surveys/?s=CJM9F7FJACKYH399")
    ReactPixel.track('enroll_button_1')
    const res = await axios.get('https://geolocation-db.com/json/')
    axios({
      method: 'post',
      url: connectNode + 'public/general/count_enroll_click',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { ip: res.data.IPv4 }
    })
    .then((result) => { })
  }

  handleEnrollClick2 = async (e) => {
    window.open("https://redcap.partners.org/redcap/surveys/?s=CJM9F7FJACKYH399&dcbi=2")
    ReactPixel.track('enroll_button_2')
    const res = await axios.get('https://geolocation-db.com/json/')
    console.log(res.data.IPv4)
    axios({
      method: 'post',
      url: connectNode + 'public/general/count_enroll_click',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { ip: res.data.IPv4 }
    })
    .then((result) => { })
  }

  changeLanguage = (language) => {
    this.setState({ language: language })
  }

  render() {
    return (
      <div class="research COMPARE">
      <Helmet>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-T4X5MNWQDK"></script>
      <script src="../gtag.js"></script>
      <script src="../gtag_manager.js"></script>
      </Helmet>
      <noscript><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=1230911777821192&ev=PageView&noscript=1"
/></noscript>
      <div class="container-fluid sectHeader">
        <div class="row d-flex align-items-center no-margin">
          <div class="col-md-3 offset-md-1 col-sm-4 offset-sm-1 col-xs-6 offset-xs-3">
          <img src="/img/CompareLogo_Tag_EOMH.png" width="600" height="163" alt="COMPARE: Expanding Options for Healthcare"/>
          </div>
          <div class="col-md-6 offset-md-1 col-sm-5 offset-sm-1 col-xs-8 offset-xs-2 text-sm-end text-center header_hospitals">
            <div className="language_bar">
              <div className={"lang_es "+((this.state.language=='es' && 'active')||(this.state.language=='en' && 'inactive'))}>
                <a onClick={(e)=>this.changeLanguage('es')}>ES</a>
              </div>
              <div className={"lang_en "+((this.state.language=='en' && 'active')||(this.state.language=='es' && 'inactive'))}>
                <a onClick={(e)=>this.changeLanguage('en')}>EN</a>
              </div>
              {this.state.language == 'en' &&
              <div className="arrow_bar1">
              <span className="">Para español</span>
              <NavigationIcon className="arrow1"/>
              </div>}
              {this.state.language == 'es' &&
              <div className="arrow_bar2">
              <div className="row">
                <div className="small-11 columns no-padding align_right">
                  For English, click here
                </div>
                <div className="small-1 columns no-padding">
                  <NavigationIcon className="arrow2"/>
                </div>
              </div>
              </div>}
            </div>
            <p>Massachusetts General Hospital</p>
            <p>Butler Hospital</p>
            <p>Hennepin Healthcare</p>
            <p>Atrium Wake Forest Baptist</p>
          </div>
        </div>
      </div>
      <div class="container-fluid frontIntro">
        <div class="row d-flex align-items-center g-md-5">
          <div class="col-md-3 offset-md-1 colText blocked">
          <div className="show-only-desktop">
          {this.state.language == 'en' &&
          <div className="video_title">Douglas Katz, PhD</div>
          }
          {this.state.language == 'es' &&
          <div className="video_title">Antonietta Alvarez Hernandez</div>
          }
          <br />
          </div>
          {this.state.language == 'en' && <ReactPlayer url={'/videos/COMPARE_music.mp4'} width='100%'
      controls = {true} light = '/img/staff/Douglas-Katz.jpg' className="show-only-desktop" />}
          {this.state.language == 'es' && <ReactPlayer url={'/videos/COMPARE_spanish.mp4'} width='100%'
      controls = {true} light = '/img/Antonietta.png' className="show-only-desktop" />}
            <p className="show-only-desktop" >&nbsp;</p>
            <h2>
            {this.state.language == 'en' && 'Feeling down?'}
            {this.state.language == 'es' && '¿Se siente decaído?'}
            </h2>
            <p><em>
            {this.state.language == 'en' && 'Feeling down and can’t seem to snap out of it?'}
            {this.state.language == 'es' && '¿Se siente decaído y no consigue recuperarse?'}
            </em></p>
            <p>
            {this.state.language == 'en' && 'You may be suffering from depression.'}
            {this.state.language == 'es' && 'Es posible que esté sufriendo de depresión. '}</p>
            <h2>
            {this.state.language == 'en' && 'The good news:'}
            {this.state.language == 'es' && '¿Las buenas noticias?'}</h2>
            <p>
            {this.state.language == 'en' && 'Researchers are working on new treatments all the time.'}
            {this.state.language == 'es' && 'Investigadores están trabajando continuamente en nuevos tratamientos'}
            </p>
            <div className="show-only-mobile">
            {this.state.language == 'en' &&
            <div className="video_title">Douglas Katz, PhD</div>
            }
            {this.state.language == 'es' &&
            <div className="video_title">Antonietta Alvarez Hernandez</div>
            }
            <br />
            </div>
            <p className="show-only-mobile">
            {this.state.language == 'en' &&
            <ReactPlayer url={'/videos/COMPARE_music.mp4'} width='100%'
            controls = {true} light = '/img/Douglas-Katz.jpg' />}
            {this.state.language == 'es' &&
            <ReactPlayer url={'/videos/COMPARE_spanish.mp4'} width='100%'
        controls = {true} light = '/img/Antonietta.png' />}
            </p>{/*playing*/}
          </div>
          <div class="col-md-7 offset-md-0 colText right_txt">
            <h1 class="black_txt bottom-space1">
            {this.state.language == 'en' && 'The COMPARE Study'}
            {this.state.language == 'es' && 'PAGINA DE INICIO DEL ESTUDIO COMPARE'}
            </h1>
            <h1 class="black_txt top-space2">
            {this.state.language == 'en' && 'Join us!'}
            {this.state.language == 'es' && '¡Únase a Nosotros!'}
            </h1>
            <p class="black_txt top-space1">
            {this.state.language == 'en' && 'If you join our study, you can get treatment that may help your depression.'}
            {this.state.language == 'es' && 'Si se une a nuestro estudio, es posible que reciba ayuda para su depresión.'}</p>
            <p class="black_txt">
            {this.state.language == 'en' && 'At the same time, you may be able to help other people who struggle with depression.'}
            {this.state.language == 'es' && 'Al mismo tiempo, puede ayudar a otras personan que están luchando contra la depresión.'}
             </p>

            <div class="row d-flex">
              <div class="col-md-12 font-size-1">
                <p class="black_txt">
                {this.state.language == 'en' && 'When you join, you’ll receive either:'}
                {this.state.language == 'es' && 'Cuando se una, recibirá una de las siguientes intervenciones:'}
                </p>

                <div class="row1 show-only-mobile yoga_box1">
                  <div className="txt_w1">
                    <p><img src="/img/black1.png" class="bullet1" /><strong>
                    {this.state.language == 'en' && '12 weeks of yoga classes'}
                    {this.state.language == 'es' && '12 semanas de clases de yoga'}</strong></p>
                    <p class="black_txt txt_w3">
                    {this.state.language == 'en' && 'OR'}
                    {this.state.language == 'es' && 'O'}</p>
                  </div>
                  <div className="txt_w4">
                    <p><img src="/img/black1.png" class="bullet1" /><strong>
                    {this.state.language == 'en' && '12 weeks of talk therapy'}
                    {this.state.language == 'es' && '12 semanas de terapia de conversación'}</strong></p>
                  </div>
                </div>

                <div className="show-only-desktop">
                  <p><img src="/img/black1.png" class="bullet1" /><strong>
                  {this.state.language == 'en' && '12 weeks of yoga classes'}
                  {this.state.language == 'es' && '12 semanas de clases de yoga'}</strong></p>
                  <p class="black_txt">
                  {this.state.language == 'en' && 'OR'}
                  {this.state.language == 'es' && 'O'}</p>
                  <p><img src="/img/black1.png" class="bullet1" /><strong>
                  {this.state.language == 'en' && '12 weeks of talk therapy'}
                  {this.state.language == 'es' && '12 semanas de terapia de conversación'}</strong></p>
                </div>
              </div>
            </div>
            <p class="black_txt">
            {this.state.language == 'en' && 'You can also get up to $170.00 for filling out surveys.'}
            {this.state.language == 'es' && 'También puede obtener hasta $170.00 por llenar nuestras encuestas. ¿Preguntas? ¡Llámenos!'}</p>
            <div class="row d-flex">
              <div className="col-md-6 enroll_button1 show-only-desktop">
                <p className="question_call align_left">
                  <h1 className="black_txt">
                  {this.state.language == 'en' && 'Questions? Call us!'}
                  {this.state.language == 'es' && '¿Preguntas? ¡Llámanos!'}</h1>
                  MA: 617-643-0857<br />
                  RI: 401-479-3698<br />
                  {this.state.language == 'en' && 'MN: 612-219-3024'}
                  {this.state.language == 'en' && <br />}
                  {this.state.language == 'en' && 'NC: 336-716-7263'}
                </p>
              </div>
              <div className="col-md-6 enroll_button1 show-only-desktop">
              <p class="black_txt enroll_button2">
              <a class="btn btn-primary" id="enroll_1"
              onClick={(e) => this.handleEnrollClick(e)}
              role="button">
              {this.state.language == 'en' && 'Click here to enroll'}
              {this.state.language == 'es' && 'ENLACE AL CUESTIONARIO DE PRESELECCIÓN'}
              </a></p>
              </div>
              <div className="col-md-6 show-only-mobile">
                <p>
                <a class="btn btn-primary" id="enroll_1"
                onClick={(e) => this.handleEnrollClick(e)}
                role="button">
                {this.state.language == 'en' && 'Click here to enroll'}
                {this.state.language == 'es' && 'ENLACE AL CUESTIONARIO DE PRESELECCIÓN'}
                </a>
                </p>
                <p className="question_call">
                  <h1 className="black_txt">
                  {this.state.language == 'en' && 'Questions? Call us!'}
                  {this.state.language == 'es' && '¿Preguntas? ¡Llámanos!'}
                  </h1>
                  MA: 617-643-0857<br />
                  RI: 401-479-3698<br />
                  {this.state.language == 'en' && 'MN: 612-219-3024'}
                  {this.state.language == 'en' && <br />}
                  NC: 336-716-7263
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid sectionJoinUs">
      <div class="row d-flex">
        <div class="col-lg-5 offset-lg-1 order-lg-1 col-md-10 offset-md-1 order-md-2 order-sm-2 order-xs-2 colText">
        <h2>
        {this.state.language == 'en' && 'Our study'}
        {this.state.language == 'es' && 'EL ESTUDIO COMPARE'}</h2>
        {this.state.language == 'en' &&
        <p>
        Researchers and clinicians from four major health care centers in the U.S. are getting together to study new treatments right now. Our new study is called <strong>COMPARE.</strong> We want to compare two treatments for depression:</p>}
        {this.state.language == 'es' &&
        <p>
        En este momento, investigadores y médicos clínicos de cuatro importantes centros de atención médica en los EE. UU. se están reuniendo para estudiar nuevos tratamientos. Nuestro estudio se llama <strong>COMPARE.</strong> Queremos comparar dos tratamientos para la depresión:</p>}
        {this.state.language == 'en' &&
        <ul>
          <li>A gentle, easy-to-do form of yoga focused on breathing, stretching, and relaxation. </li>
          <li>A talk therapy called<em> behavioral activation</em> that helps motivate people to get out and do things that are important to them. </li>
        </ul>}
        {this.state.language == 'es' &&
        <ul>
          <li>Una forma de yoga suave y fácil de practicar que se centra en la respiración, el estiramiento y la relajación.</li>
          <li>Una terapia de conversación llamada <em>activación conductual</em> que ayuda a motivar a las personas a salir y hacer cosas que son importantes para ellas.</li>
        </ul>}
        <p>
        {this.state.language == 'en' && 'If we find out that both of these ways of treating depression work, we’ll have more ways to help people with depression feel better.'}
        {this.state.language == 'es' && 'Si descubrimos que estos dos métodos de tratamiento para la depresión funcionan, tendremos más formas de ayudar a las personas con depresión a sentirse mejor.'}</p>
        {this.state.language == 'en' &&
        <p>But to do this, <strong>we need your help</strong>.</p>}
        {this.state.language == 'es' &&
        <p>Pero para lograr esto, <strong>necesitamos tu ayuda</strong>.</p>}
        </div>
        <div class="col-lg-5 offset-lg-1 order-lg-2 col-md-12 offset-md-0 order-md-1 order-sm-1 order-xs-1 colPhoto" >
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>
      </div>
      </div>

      <div class="container-fluid sectionStudy">
        <div class="row align-items-center">
          {this.state.language == 'en' &&
          <div class="col-md-6 offset-md-6 colText">
            <p>To join the COMPARE study, you must: </p>
            <ul>
              <li>Be 18 years of age or older </li>
              <li>Have symptoms of depression or sad mood</li>
              <li>Be able to read English or Spanish (Minnesota residents must read English)</li>
              <li>Live in Massachusetts, Minnesota, North Carolina, or Rhode Island </li>
            </ul>
          </div>}
          {this.state.language == 'es' &&
          <div class="col-md-6 offset-md-6 colText">
            <p>Para unirse al estudio COMPARE, debe: </p>
            <ul>
              <li>Tener 18 años de edad o más</li>
              <li>Tener síntomas de depresión o tristeza</li>
              <li>Ser capaz de leer en inglés o español</li>
              <li>Vivir en Massachusetts o Rhode Island</li>
            </ul>
          </div>}
        </div>
      </div>
      <div class="container-fluid sectionCalltoAction">
        <div class="row align-items-center">
          {this.state.language == 'en' &&
          <div class="col-lg-3 offset-lg-1 col-md-10 offset-md-1 colText blocked">
            <h2>What happens after you’re done?</h2>
            <p>After the study is over, we will look at all of the information we have to see how much the yoga classes and therapy sessions helped our participants  feel better. We will let you know what we learn. </p>
          </div>}
          {this.state.language == 'es' &&
          <div class="col-lg-3 offset-lg-1 col-md-10 offset-md-1 colText blocked">
            <h2>¿Qué sucede al finalizar?</h2>
            <p>Una vez finalizado el estudio, analizaremos toda la información que tenemos para ver cuánto ayudaron las clases de yoga y las sesiones de terapia a nuestros participantes a sentirse mejor. Le informaremos sobre lo que averigüemos.</p>
          </div>}
          {this.state.language == 'en' &&
          <div class="col-lg-6 offset-lg-1 col-md-10 offset-md-1 colText">
            <h2><img src="/img/person-computer.png"  class="img-fluid float-end" alt=""/>How can I join the study? </h2>
            <p>Just click on the link below. You’ll be asked to answer some questions to make sure that the study is a good fit for you. Then you can continue to enroll. </p>
            <p><a class="btn btn-primary" id="enroll_2" onClick={(e) => this.handleEnrollClick2(e)} role="button" role="button">Click here to enroll</a></p>
            <p class="smaller">If you have questions about the study or how to join, you can call us at
            336-716-7263 (Atrium Wake Forest Baptist, NC), 401-479-3698 (Butler Hospital, RI),
            612-219-3024 (Hennepin Healthcare, MN), 617-643-0857 (Massachusetts General Hospital, MA).
            We’ll be happy to tell you more about the study. We can also help you enroll.</p>
          </div>}
          {this.state.language == 'es' &&
          <div class="col-lg-6 offset-lg-1 col-md-10 offset-md-1 colText">
            <h2><img src="/img/person-computer.png"  class="img-fluid float-end" alt=""/>¿Cómo puedo unirme al estudio?</h2>
            <p>Simplemente haga clic en el enlace que aparece a continuación. Se le pedirá que responda algunas preguntas para asegurarse de que el estudio sea adecuado para usted. Luego, podrá seguir inscribiéndose en el estudio.</p>
            <p><a class="btn btn-primary" id="enroll_2" onClick={(e) => this.handleEnrollClick2(e)} role="button">ENLACE AL CUESTIONARIO DE PRESELECCIÓN</a></p>
            <p class="smaller">Si tiene preguntas sobre el estudio o sobre cómo unirse, puede llamarnos al 617-643-0857 (MA), 401-479-3698 (RI). Con gusto, le contaremos más sobre el estudio. También podemos ayudarlo a inscribirse.</p>
          </div>}
        </div>
      </div>
      <div class="container-fluid sectPeopleStrip">
        <div class="row">
          <div class="col"> <img src="/img/people-strip.jpg" width="2015" height="245" alt=""/> </div>
        </div>
      </div>
      <div class="container-fluid sectionFooter">
        <div class="row align-items-center">
          <div class="col-md-10 offset-md-1">
            <p>&nbsp;<br />
              Copyright © 2007-2022. The General Hospital Corporation. All Rights Reserved. | Image by <a href="https://www.freepik.com/free-photo/medium-shot-women-talking-therapy_13360967.htm" target="_blank">Freepik</a></p>
          </div>
        </div>
      </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ResearchCompareNew1)
