import React from "react"
import Login from "../components/auth/login"
import Home from "../components/public/home"
import About from "../components/public/about"
import AboutStaff from "../components/public/aboutstaff"
import Bipolar from "../components/public/bipolar"
import OurClinic from "../components/public/ourclinic"
import ContactDauten from "../components/public/contact_dauten"
import OurResearch from "../components/public/our_research"
import Research from "../components/public/research"
import Contact from "../components/public/contact"
import News from "../components/public/news"
import Node from "../components/public/node"
import ResearchCompare from "../components/public/research_compare"
import ResearchCompareNew1 from "../components/public/research_compare_new_1"
import ResearchHeatedYoga from "../components/public/research_heated_yoga"
import ResearchCompareMGH from "../components/public/research_compare_mgh"
import ResearchCompareButler from "../components/public/research_compare_butler"
import ResearchCompareHennepin from "../components/public/research_compare_hennepin"
import ResearchCompareAtrium from "../components/public/research_compare_atrium"
import ResearchCompareResourceList from "../components/public/research_compare_resource_list"

const PUBLIC_ROUTES = [
  {   path: "/",
      key: "home",
      title: "Home",
      exact: true,
      requireAuth: false,
      component: () => <Home />,
      showtitle: 'true',
  },
  {   path: "/login",
      key: "login",
      title: "Login",
      exact: true,
      requireAuth: false,
      component: () => <Login />,
      showtitle: 'true',
  },
  {   path: "/about/:page",
      key: "about",
      title: "About",
      exact: true,
      requireAuth: false,
      component: () => <About />,
      showtitle: 'true',
  },
  {   path: "/about_team/:staff",
      key: "about",
      title: "About",
      exact: true,
      requireAuth: false,
      component: () => <AboutStaff />,
      showtitle: 'true',
  },
  {   path: "/bipolar/:page",
      key: "bipolar",
      title: "Bipolar Disorder",
      exact: true,
      requireAuth: false,
      component: () => <Bipolar />,
      showtitle: 'true',
  },
  {   path: "/our-clinic/:page",
      key: "our-clinic",
      title: "Our Clinic",
      exact: true,
      requireAuth: false,
      component: () => <OurClinic />,
      showtitle: 'true',
  },
  {   path: "/contact/:page",
      key: "contact",
      title: "Contact",
      exact: true,
      requireAuth: false,
      component: () => <ContactDauten />,
      showtitle: 'true',
  },
  {   path: "/our-research/:page",
      key: "our-research",
      title: "Our Research",
      exact: true,
      requireAuth: false,
      component: () => <OurResearch />,
      showtitle: 'true',
  },
  {   path: "/researches/:page",
      key: "research",
      title: "Research",
      exact: true,
      requireAuth: false,
      component: () => <Research />,
      showtitle: 'true',
  },
  {   path: "/contact-us",
      key: "contact-us",
      title: "Contact Us",
      exact: true,
      requireAuth: false,
      component: () => <Contact />,
      showtitle: 'true',
  },
  {   path: "/news-events-list",
      key: "news-events-list",
      title: "News",
      exact: true,
      requireAuth: false,
      component: () => <News />,
      showtitle: 'true',
  },
  {   path: "/node/:nid",
      key: "node",
      title: "Node",
      exact: true,
      requireAuth: false,
      component: () => <Node />,
      showtitle: 'true',
  },
  {   path: "/research/compare",
      key: "compare",
      title: "The COMPARE Study: Dauten Family Center for Bipolar Treatment Innovation",
      exact: true,
      requireAuth: false,
      component: () => <ResearchCompareNew1 />,
      showtitle: 'true',
  },
  {   path: "/research/heated_yoga",
      key: "compare",
      title: "Dauten Family Center for Bipolar Treatment Innovation",
      exact: true,
      requireAuth: false,
      component: () => <ResearchHeatedYoga />,
      showtitle: 'true',
  },
  /*{   path: "/research/compare_new_1",
 *       key: "compare",
 *             title: "The COMPARE Study: Dauten Family Center for Bipolar Treatment Innovation",
 *                   exact: true,
 *                         requireAuth: false,
 *                               component: () => <ResearchCompareNew1 />,
 *                                     showtitle: 'true',
 *                                       },*/
  {   path: "/research/compare_mgh",
      key: "compare",
      title: "The COMPARE Study: Dauten Family Center for Bipolar Treatment Innovation",
      exact: true,
      requireAuth: false,
      component: () => <ResearchCompareMGH />,
      showtitle: 'true',
  },
  {   path: "/research/compare_butler",
      key: "compare",
      title: "The COMPARE Study: Dauten Family Center for Bipolar Treatment Innovation",
      exact: true,
      requireAuth: false,
      component: () => <ResearchCompareButler />,
      showtitle: 'true',
  },
  {   path: "/research/compare_hennepin",
      key: "compare",
      title: "The COMPARE Study: Dauten Family Center for Bipolar Treatment Innovation",
      exact: true,
      requireAuth: false,
      component: () => <ResearchCompareHennepin />,
      showtitle: 'true',
  },
  {   path: "/research/compare_atrium",
      key: "compare",
      title: "The COMPARE Study: Dauten Family Center for Bipolar Treatment Innovation",
      exact: true,
      requireAuth: false,
      component: () => <ResearchCompareAtrium />,
      showtitle: 'true',
  },
  {   path: "/research/resource_list",
      key: "compare",
      title: "COMPARE National Well-being Resources",
      exact: true,
      requireAuth: false,
      component: () => <ResearchCompareResourceList />,
      showtitle: 'true',
  },
  {   path: "/admin/login",
      key: "login",
      title: "Login",
      exact: true,
      requireAuth: false,
      component: () => <Login />,
      showtitle: 'true',
  },

];

export default PUBLIC_ROUTES;
