import React, { Component } from 'react'
import { connect } from 'react-redux'
import AuthService from '../../services/auth/auth.service.js'
import { logoutUser } from '../../actions/auth'
import { Link } from "react-router-dom"
import Image1 from '../../images/icon_facebook.png'
import Image2 from '../../images/mood_network_logo.png'

class Footer extends Component {
  constructor() {
    super();
    this.state = {
      route: '',
      message: '',
    }
  }

  onReloadPages = () => {
    this.props.onLoadPages()
  }

  render() {
    var url_origin = window.location.origin
    return (
      <div class="container-fluid sectionFooter">
        <div class="row bottomNav">
          <div class="col-md-10 offset-md-1">
            <ul class="nav justify-content-center">
              <li class="nav-item"> <a class="nav-link active" aria-current="page" href="/">Home</a> </li>
              <li class="nav-item"> <a class="nav-link" href="/about/our-team">About Us</a> </li>
              <li class="nav-item"> <a class="nav-link" href="/bipolar/about-bipolar-disorder">Bipolar Disorder</a> </li>
              {/*<li class="nav-item"> <a class="nav-link" href="/our-clinic/dauten-fitt-bd">Our Clinic</a></li>*/}
              <li class="nav-item"> <a class="nav-link" href="/our-research/current-projects">Research</a> </li>
              <li class="nav-item"> <a class="nav-link" href="/contact/contact">Contact</a> </li>
            </ul>
          </div>
        </div>
        <div class="row contactInfo">
          <div class="col-md-5 offset-md-0 text-md-start"><strong>Dauten Family Center for Bipolar Treatment Innovation</strong><br />
            50 Staniford St., Suite 580<br />
            Boston, MA, 02114</div>
          <div class="col-md-5 offset-md-2 text-md-end">(617) 643-2076<br />
            Weekdays between 9:00 a.m. and 5:00 p.m.</div>
        </div>
        <div class="row align-items-center">
          <div class="col-md-10 offset-md-1">
            <p>&nbsp;<br />
              Copyright © 2007-2024. The General Hospital Corporation. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { uid: state.authReducer.uid }
}

export default connect(mapStateToProps)(Footer)
