import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'
import '../../styles/assets/js/bootstrap.min.css'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import ReactPixel from 'react-facebook-pixel'
import axios from 'axios'
import { connectNode } from '../../utils/global'
import { loadLocationWithMessage } from '../../actions/location'
import '../../styles/assets/css/compare/dauten.css'
import '../../styles/assets/css/rhyme/rhyme.css'
import { Helmet } from "react-helmet"
import ReactPlayer from 'react-player'
import NavigationIcon from '@material-ui/icons/Navigation'

class ResearchHeatedYoga extends Component {
  constructor() {
      super()
      this.state = {
        language: 'en'
      }
  }

  componentDidMount() {
    ReactPixel.init('1230911777821192');
    ReactPixel.pageView()
  }

  handleEnrollClick = async (e) => {
    window.open("https://redcap.partners.org/redcap/surveys/?s=CJM9F7FJACKYH399")
    ReactPixel.track('enroll_button_1')
    const res = await axios.get('https://geolocation-db.com/json/')
    axios({
      method: 'post',
      url: connectNode + 'public/general/count_enroll_click',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { ip: res.data.IPv4 }
    })
    .then((result) => { })
  }

  handleEnrollClick2 = async (e) => {
    window.open("https://redcap.partners.org/redcap/surveys/?s=CJM9F7FJACKYH399&dcbi=2")
    ReactPixel.track('enroll_button_2')
    const res = await axios.get('https://geolocation-db.com/json/')
    console.log(res.data.IPv4)
    axios({
      method: 'post',
      url: connectNode + 'public/general/count_enroll_click',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { ip: res.data.IPv4 }
    })
    .then((result) => { })
  }

  changeLanguage = (language) => {
    this.setState({ language: language })
  }

  render() {
    return (
      <div class="research COMPARE rhyme">
      <noscript><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=1230911777821192&ev=PageView&noscript=1"
/></noscript>
      <div class="container-fluid sectHeader">
        <div class="row d-flex align-items-center no-margin">
          <div class="col-md-3 offset-md-1 col-sm-4 offset-sm-1 col-xs-6 offset-xs-3">
          <img src="/img/mgh-logo-rgb.svg" width="600" height="163" alt="COMPARE: Expanding Options for Healthcare"/>
          </div>
        </div>
      </div>
      <div class="container-fluid frontIntro">
        <div class="row d-flex align-items-center g-md-5">
          <div class="col-md-3 offset-md-1 colText blocked">
          <div className="show-only-desktop">
          {this.state.language == 'en' &&
          <div className="video_title">Maren B. Nyer, PhD</div>
          }
          {this.state.language == 'es' &&
          <div className="video_title">Maren B. Nyer, PhD</div>
          }
          <br />
          </div>
          {this.state.language == 'en' && <ReactPlayer url={'/videos/COMPARE_music.mp4'} width='100%'
      controls = {true} light = '/pictures/Maren.jpg' className="show-only-desktop" />}
          {this.state.language == 'es' && <ReactPlayer url={'/videos/COMPARE_spanish.mp4'} width='100%'
      controls = {true} light = '/pictures/Maren.jpg' className="show-only-desktop" />}
            <p className="show-only-desktop" >&nbsp;</p>
            <p>Treatment for depression and sad mood is not <b>‘one-size-fits-all.’</b></p>
            <p>That’s why researchers at MGH want to study <b>alternatives to traditional talk therapies</b>.</p>
            <div className="show-only-mobile">
            {this.state.language == 'en' &&
            <div className="video_title">Antonietta Alvarez Hernandez</div>
            }
            {this.state.language == 'es' &&
            <div className="video_title">Antonietta Alvarez Hernandez</div>
            }
            <br />
            </div>
            <p className="show-only-mobile">
            {this.state.language == 'en' &&
            <ReactPlayer url={'/videos/COMPARE_music.mp4'} width='100%'
            controls = {true} light = '/img/Antonietta.png' />}
            {this.state.language == 'es' &&
            <ReactPlayer url={'/videos/COMPARE_spanish.mp4'} width='100%'
        controls = {true} light = '/img/Antonietta.png' />}
            </p>{/*playing*/}
          </div>
          <div class="col-md-7 offset-md-0 colText right_txt top-space5">
            <h1 class="black_txt top-space2">The RHYME Study</h1>
            <p class="black_txt top-space1 font-size-1">Be part of something new: Join Us!</p>
            <p class="black_txt top-space1 font-size-1">If you join us, you’ll get treatment that may help your mood.</p>
            <p class="black_txt top-space1 font-size-1">You may also help others who suffer from depression.</p>
            <p>&nbsp;</p>

            <div class="row d-flex">
              <div class="col-md-12 font-size-1">
                <p class="black_txt">
                {this.state.language == 'en' && 'When you join, you’ll receive either:'}
                {this.state.language == 'es' && 'Cuando se una, recibirá una de las siguientes intervenciones:'}
                </p>

                <div class="row1 show-only-mobile yoga_box1">
                  <div className="txt_w1">
                    <p><img src="/img/black1.png" class="bullet1" /><strong>8 weeks of heated yoga classes</strong></p>
                    <p class="black_txt txt_w3">
                    {this.state.language == 'en' && 'OR'}
                    {this.state.language == 'es' && 'O'}</p>
                  </div>
                  <div className="txt_w4">
                    <p><img src="/img/black1.png" class="bullet1" /><strong>8 weeks of non-heated yoga classes</strong></p>
                  </div>
                </div>

                <div className="show-only-desktop">
                  <p><img src="/img/black1.png" class="bullet1" /><strong>8 weeks of heated yoga classes</strong></p>
                  <p class="black_txt">
                  {this.state.language == 'en' && 'OR'}
                  {this.state.language == 'es' && 'O'}</p>
                  <p><img src="/img/black1.png" class="bullet1" /><strong>8 weeks of non-heated yoga classes</strong></p>
                </div>
              </div>
            </div>
            <p className="black_txt">All yoga classes are free of charge.</p>
            <p className="black_txt">You can also earn up to $310 for filling out study surveys.</p>
            <div class="row d-flex">
              <div className="col-md-6 enroll_button1 show-only-desktop">
                <p className="question_call align_left">
                  <h1 className="black_txt">
                  {this.state.language == 'en' && 'Questions? Call us!'}
                  {this.state.language == 'es' && '¿Preguntas? ¡Llámanos!'}</h1>
                  XXX-XXX-XXXX
                </p>
              </div>
              <div className="col-md-6 show-only-mobile">
                <p>
                <a class="btn btn-primary" id="enroll_1"
                onClick={(e) => this.handleEnrollClick(e)}
                role="button">Click here to join</a>
                </p>
                <p className="question_call">
                  <h1 className="black_txt">
                  {this.state.language == 'en' && 'Questions? Call us!'}
                  {this.state.language == 'es' && '¿Preguntas? ¡Llámanos!'}
                  </h1>
                  MA: 617-643-0857<br />
                  RI: 401-479-3698<br />
                  {this.state.language == 'en' && 'MN: 612-219-3024'}
                  {this.state.language == 'en' && <br />}
                  NC: 336-716-7263
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid sectionJoinUsRhyme">
      <div class="row d-flex">
        <div class="col-lg-5 offset-lg-1 order-lg-1 col-md-10 offset-md-1 order-md-2 order-sm-2 order-xs-2 colText">
        <h2>About our study:</h2>
        <p className="r_txt1"><b><i>Researching Heat and Yoga for Mood Enhancement</i></b> (RHYME) will compare heated and non-heated yoga as treatments for depression and sad mood in adults.</p>
        <p className="r_txt1">Classes will be held:
        <ul>
          <li>Two times/week</li>
          <li>For 90 minutes </li>
          <li>Over eight weeks</li>
        </ul>
        All classes will be in person in Harvard Square, Cambridge, and accessible by public transportation.</p>
        <p className="r_txt1">Help expand treatment options for depression and join our study today!</p>

        </div>
        <div class="col-lg-5 offset-lg-1 order-lg-2 col-md-12 offset-md-0 order-md-1 order-sm-1 order-xs-1 colPhoto" >
          <img src="/rhyme/rhyme_yoga1.png" />
        </div>
      </div>
      </div>

      <div class="container-fluid sectionStudy">
        <div class="row align-items-center">
          <div class="col-md-6 offset-md-6 colText">
            <p>To join the RHYME study, you must:</p>
            <ul>
              <li>Be between 18 and 65 years of age</li>
              <li>Have symptoms of depression or sad mood</li>
              <li>Be able to read English</li>
              <li>Be able to travel to the Harvard Square, Cambridge</li>
              <li>Attend at least two of the yoga classes offered each week</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="container-fluid sectionCalltoAction">
        <div class="row align-items-center">
          <div class="col-lg-3 offset-lg-1 col-md-10 offset-md-1">
            <img src="/rhyme/rhyme_yoga2.jpg" className="left_img" />
          </div>
          <div class="col-lg-6 offset-lg-1 col-md-10 offset-md-1 colText">
            <h2>How can I join the study? </h2>
            <p>Just click on the link below. You’ll be asked to answer some questions to make sure that the study is a good fit for you.</p>
            <p class="question_call align_left"><h2 class="black_txt">Questions? Call us!</h2>XXX-XXX-XXXX</p>
          </div>
        </div>
      </div>
      <div class="container-fluid sectPeopleStrip">
        <div class="row">
          <div class="col"> <img src="/img/people-strip.jpg" width="2015" height="245" alt=""/> </div>
        </div>
      </div>
      <div class="container-fluid sectionFooter">
        <div class="row align-items-center">
          <div class="col-md-10 offset-md-1">
            <p>&nbsp;<br />
              Copyright © 2007-2022. The General Hospital Corporation. All Rights Reserved. | Image by <a href="https://www.freepik.com/free-photo/medium-shot-women-talking-therapy_13360967.htm" target="_blank">Freepik</a></p>
          </div>
        </div>
      </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ResearchHeatedYoga)
