import React, { Component } from 'react'
import { connect } from 'react-redux'
import AuthService from '../../services/auth/auth.service.js'
import { logoutUser } from '../../actions/auth'
//import { connectNode } from '../../utils/global.js'
import { Link, NavLink } from "react-router-dom"
import { loadLocation } from '../../actions/location'
import Image from '../../images/Dauten_logo1.png'
import { Nav, Navbar, NavDropdown } from "react-bootstrap"

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawerOpened: false,
      login: false
    };
  }

  toggleDrawer = booleanValue => () => {
    this.setState({
      drawerOpened: booleanValue
    });
  };

  onReloadPages = () => {
    this.props.onLoadPages()
  }

  handleLogout() {
    AuthService.logout()
    this.onReloadPages()
    this.onReloadLocation('/login')
    document.location.href = '/login'
  }

  onReloadLocation = (route) => {
    this.props.onLoadLocation(route)
  }

  handlePage(route) {
    this.onReloadLocation(route)
  }

  render() {
    var url_origin = window.location.origin
    var innerWidth = window.innerWidth
    return (
      <Navbar collapseOnSelect expand="lg" bg="white" variant="white">
      <div className="container-fluid">
        <Navbar.Brand href="#home">
          <a className="navbar-brand" href="/"><img src={Image} className="img-fluid" width="360" height="76" alt=""/></a>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <NavDropdown title="About Us" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/about/our-team">Our Team</NavDropdown.Item>
              <NavDropdown.Item href="/about/dauten-family">Dauten Family</NavDropdown.Item>
              <NavDropdown.Item href="/about/scientific-advisory-board">Scientific Advisory Board</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav className="ms-auto">
            <NavDropdown title="Bipolar Disorder" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/bipolar/about-bipolar-disorder">About Bipolar Disorder</NavDropdown.Item>
              <NavDropdown.Item href="/bipolar/informational-videos">Informational Videos</NavDropdown.Item>
              <NavDropdown.Item href="/bipolar/resources">Resources</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          {/*<Nav className="ms-auto">
            <NavDropdown title="Our Clinic" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/our-clinic/dauten-fitt-bd">Overview</NavDropdown.Item>
              <NavDropdown.Item href="https://fitt-bd.org/introduction/introduction1">FITT-BD</NavDropdown.Item>
              <NavDropdown.Item href="/our-clinic/training">Training Opportunities</NavDropdown.Item>
            </NavDropdown>
          </Nav>*/}
          <Nav className="ms-auto">
            <NavDropdown title="Our Research" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/our-research/current-projects">Our Research</NavDropdown.Item>
              <NavDropdown.Item href="/our-research/publications">Publications</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav className="ms-auto">
            <NavDropdown title="Contact" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/contact/contact">How to Reach Us</NavDropdown.Item>
              <NavDropdown.Item href="/contact/training">Training Opportunities</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav className="ms-auto">
            <Nav.Link href="https://giving.massgeneral.org/donate?re_fund=024376&is_designation=1&designation=Dauten%20Family%20Center%20for%20Bipolar%20Treatment%20Innovation">Donate</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        </div>
      </Navbar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.authReducer.uid,
    role: state.authReducer.role,
    p_st: state.authReducer.p_st,
    username: state.authReducer.username,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages() {
    dispatch(logoutUser())
  },
  onLoadLocation(route) {
    dispatch(loadLocation(route))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
